.horarios-table {
    width: 100%;
}

.horarios-table, .horarios-table tr, .horarios-table td, .horarios-table th {
    border: 1px solid #EBEBEB;
}

.horarios-table > thead > tr > th {
    padding: 20px 5px;
}

.horarios-table > thead > tr > th > h3 {
    text-align: center;
}

.horarios-table > tbody > tr > td {
    padding: 5px;
}

.horarios-table > tbody > tr > td {
    text-align: center;
}

.horarios-table > tbody > tr:hover {
    background-color:  var(--blueBG);
}