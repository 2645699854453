.libros-devueltos-table th, tr, td {
    padding: 5px;
    text-align: center;
}

.libros-devueltos-table td {
   background-color: #F3FCFF;
}

.libros-devueltos-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1em;
}

.libros-devueltos-table input {
    font-size: 16px;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: center;
}

.libros-devueltos-table input:focus {
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
    background-color: #FFF;
}

.libros-devueltos-table input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.libros-devueltos-table input[type=number] {
  -moz-appearance: textfield;
}


.libros-devueltos-table .total-unidades td{
  background-color: #F3FFFB;
}